* {
  font-family: Roboto, Helvetica;
  color: var(--main-text-color);
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: inherit;
}
